<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-clay overflow-auto">
        
        <form @submit.prevent="newPasswordSubmit()">
            <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                {{error}}
            </div>
            
            <InputSingleline 
                v-model="password"
                :label="$t('auth.newPassword')"
                name="password"
                :placeholder="$t('auth.newPassword')"
                class="w-full "
                type="password"
                :required="true"
            />
            
            <button 
                type="submit" 
                class="button submit mb-4 w-full" 
                :class="{'loading' : loading}"
                :disabled="loading">
                
                {{$t('auth.setNewPassword')}}
            </button>
        </form>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                error: null,
                password: null,
                loading: false,
                user: null,
            }
        },
        
        mounted(){
            if (!this.$route.params.user) {
                this.$router.push({name: 'signin'});
            }
            this.user = this.$route.params.user;    
        }
    }
</script>
